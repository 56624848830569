.bot-doc-viewer {
  a {
    text-decoration: underline !important;
  }
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  border: 1px;
  background-color: transparent;
  border-radius: 5px !important;
  width: 100%;
  overflow-y: auto;
  padding: 10pt;
  .actions {
    border-top: 1px solid #e5e5e5
  }
  .actions UL {
    list-style-type: none;
    margin: 0;
    padding: 5px 10px
  }
  .actions UL LI {
    font-size: 16px !important;
    float: right;
    list-style-type: none
  }

  .actions A {
    color: #0094cd;
    text-decoration: none
  }

  .actions A:hover {
    color: #9ae3ff
  }

  .responsive IMG {
    width: 100%;
    height: auto;
    vertical-align: middle
  }

  EM,VAR {
    font-style: italic;
    font-family: inherit;
    font-size: inherit
  }

  STRONG {
    font-weight: bold;
    font-family: inherit;
    font-size: inherit
  }

  SPAN {
    font-weight: inherit;
    font-family: inherit;
    font-size: inherit
  }

  H1 {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 16px
  }

  H2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    margin: 14px 0
  }

  H3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    margin: 12px 0
  }

  H4,H5,H6 {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin: 12px 0
  }

  P {
    margin-bottom: 10px
  }

  OL {
    margin-bottom: 10px;
    list-style-type: decimal
  }

  OL LI {
    list-style-type: decimal;
    margin-left: 20px
  }

  UL {
    margin-bottom: 10px
  }

  UL LI {
    margin-left: 20px
  }

  DIV.p {
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0
  }

  DIV.responsive {
    display: inline
  }

  IMG.responsive {
    width: 100%;
    height: auto;
    cursor: pointer;
    vertical-align: middle
  }

  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0
  }

  .videoWrapper IFRAME {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
  }

  a.referenceLink.fa.fa-file {
    margin-right: 4px
  }

  .chapter {
    margin-bottom: 10px
  }

  .docSection .docSectionTitle {
    display: inline-block
  }

  .docSection .docSectionActions {
    display: inherit;
    float: right;
    margin-left: auto
  }

  .docSection .docSectionHeader {
    display: flex;
    font-weight: 600;
    width: 100%;
    background-color: #d3d3d3;
    padding: 5px
  }

  .docSection .docSectionHeader:before {
    content: '+';
    padding: 0;
    margin-right: 5px
  }

  .docSection .docSectionHeader.sectionOpened:before {
    content: '-' !important
  }

  .docSection.sectionOpened .docSectionHeader.sectionOpened:before {
    content: '-' !important
  }

  .docSection .docSectionHeader:hover {
    cursor: pointer
  }

 // .docSection .docSectionBody {
 //   display: none
 // }

  .docSection.sectionOpened > .docSectionBody {
    display: inherit
  }


  VIDEO {
    width: 100% !important;
    height: auto !important
  }

  U {
    text-decoration: underline
  }

  STRIKE {
    text-decoration: line-through
  }

  SUP {
    vertical-align: super
  }

  SUB {
    vertical-align: sub
  }

  TH,TD {
    border: 1px solid;
    padding: 2px 5px
  }

  #KFHistoryButton {
    position: absolute;
    right: 30px
  }

  #KFHistoryButton.kc_invisible {
    display: none !important
  }

  .documentBody #results {
    margin-bottom: 10px
  }

  .documentBody #results .result.notDisplayed {
    display: none !important
  }

  .documentBody .optionsHolder {
    margin: 0
  }

  .documentBody .optionsHolder .selectedOption {
    display: inline-block;
    padding: 0 10px;
    border: 1px solid;
    line-height: 0;
    margin-right: 5px
  }

  .documentBody .optionsHolder .selectedOption p {
    margin-top: 10px;
    margin-bottom: 10px
  }

  .documentBody .optionsHolder .selectedOption .optionText {
    display: inline-block
  }

  .documentBody .optionsHolder .selectedOption .removeSelectedOption {
    margin-left: 15px;
    cursor: pointer
  }

  .documentBody #document-toc.chapter {
    margin-bottom: 10px
  }

  .documentBody.questionnaire #questions {
    padding-top: 10px;
    margin-bottom: 10px
  }

  .documentBody.questionnaire #questions .question {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column
  }

  .documentBody.questionnaire #questions .question.Usu_StepGuiType_QuestionAnswers .question_title {
    order: 2
  }

  .documentBody.questionnaire #questions .question.Usu_StepGuiType_QuestionAnswers .question_content {
    order: 1
  }

  .documentBody.questionnaire #questions .question.Usu_StepGuiType_QuestionAnswers .answers {
    order: 3
  }

  .documentBody.questionnaire #questions .question.Usu_StepGuiType_QuestionAnswers .dropDown {
    order: 3
  }

  .documentBody.questionnaire #questions .question.Usu_StepGuiType_QuestionAnswers .extraData {
    order: 4
  }

  .documentBody.questionnaire #questions .question.Usu_StepGuiType_QuestionDescriptionAnswers .question_title {
    order: 1
  }

  .documentBody.questionnaire #questions .question.Usu_StepGuiType_QuestionDescriptionAnswers .question_content {
    order: 2
  }

  .documentBody.questionnaire #questions .question.Usu_StepGuiType_QuestionDescriptionAnswers .answers {
    order: 3
  }

  .documentBody.questionnaire #questions .question.Usu_StepGuiType_QuestionDescriptionAnswers .dropDown {
    order: 3
  }

  .documentBody.questionnaire #questions .question.Usu_StepGuiType_QuestionDescriptionAnswers .extraData {
    order: 4
  }

  .documentBody.questionnaire #questions .question.Usu_StepGuiType_QuestionAnswersDescription .question_title {
    order: 1
  }

  .documentBody.questionnaire #questions .question.Usu_StepGuiType_QuestionAnswersDescription .question_content {
    order: 4
  }

  .documentBody.questionnaire #questions .question.Usu_StepGuiType_QuestionAnswersDescription .answers {
    order: 2
  }

  .documentBody.questionnaire #questions .question.Usu_StepGuiType_QuestionAnswersDescription .dropDown {
    order: 2
  }

  .documentBody.questionnaire #questions .question.Usu_StepGuiType_QuestionAnswersDescription .extraData {
    order: 3
  }

  .documentBody.questionnaire #questions .question.Usu_StepGuiType_CommentAnswers .question_title {
    display: none
  }

  .documentBody.questionnaire #questions .question.Usu_StepGuiType_CommentAnswers .question_content {
    order: 1
  }

  .documentBody.questionnaire #questions .question.Usu_StepGuiType_CommentAnswers .answers {
    order: 2
  }

  .documentBody.questionnaire #questions .question.Usu_StepGuiType_CommentAnswers .dropDown {
    order: 2
  }

  .documentBody.questionnaire #questions .question.Usu_StepGuiType_CommentAnswers .extraData {
    order: 3
  }

  .documentBody.questionnaire #questions .question.notDisplayed {
    display: none !important
  }

  .documentBody.questionnaire #questions .question.mandatory .question_title:after {
    content: "*";
    color: #f00
  }

  .documentBody.questionnaire #questions .question.extradata_mandatory .extraData .extraDataWrapper:after {
    content: "*";
    color: #f00
  }

  .documentBody.questionnaire #questions .question.mandatoryUnansweredQuestion .question_content:before {
    content: "The Answer is mandatory!";
    color: #f00;
    margin-bottom: 5px;
    font-size: 14px
  }

  .documentBody.questionnaire #questions .question .question_title {
    font-weight: 600
  }

  .documentBody.questionnaire #questions .question .dropDown .optionsHolder {
    margin: 0
  }

  .documentBody.questionnaire #questions .question .dropDown .optionsHolder .selectedOption {
    display: inline-block;
    padding: 0 10px;
    border: 1px solid;
    line-height: 0;
    margin-right: 5px
  }

  .documentBody.questionnaire #questions .question .dropDown .optionsHolder .selectedOption p {
    margin-top: 10px;
    margin-bottom: 10px
  }

  .documentBody.questionnaire #questions .question .dropDown .optionsHolder .selectedOption .optionText {
    display: inline-block
  }

  .documentBody.questionnaire #questions .question .dropDown .optionsHolder .selectedOption .removeSelectedOption {
    margin-left: 15px;
    cursor: pointer
  }

  .documentBody.questionnaire #questions .question .answers .answer_title {
    display: none
  }

  .documentBody.questionnaire #questions .question .answers .answer_content .answerwrapper.box input[type="radio"],.documentBody.questionnaire #questions .question .answers .answer_content .answerwrapper.box input[type="checkbox"] {
    display: none !important
  }

  .documentBody.questionnaire #questions .question .answers .answer_content .answerwrapper.box input[type="radio"]+label,.documentBody.questionnaire #questions .question .answers .answer_content .answerwrapper.box input[type="checkbox"]+label {
    border-style: solid;
    border-width: 1px;
    background-color: #fff;
    padding: 5px 20px;
    margin-top: 0;
    margin-bottom: 0;
    color: #000;
    width: 50%;
    cursor: pointer;
    display: inline-block
  }

  .documentBody.questionnaire #questions .question .answers .answer_content .answerwrapper.box input[type="radio"]:checked+label,.documentBody.questionnaire #questions .question .answers .answer_content .answerwrapper.box input[type="checkbox"]:checked+label {
    background-color: #3c0
  }

  .documentBody.questionnaire #questions .question .answers .answer_content .answerwrapper INPUT {
    margin-right: 10px
  }

  .documentBody.questionnaire #questions .question .answers .answer_content .answerwrapper LABEL {
    font-size: 14px
  }

  .documentBody.questionnaire #questions .question .extraData .extraDataWrapper TEXTAREA {
    background: #fff;
    color: #2b425b;
    border: 1px solid #2b425b;
    width: 95%;
    margin: 10px 0;
    padding: 2px
  }

  .documentBody.questionnaire #questions .question .extraData .extraDataWrapper TEXTAREA:focus {
    border: 1px solid #00f
  }

  .documentBody.questionnaire #questions .question .extraData .extraDataWrapper input[type=text] {
    background: #fff;
    color: #2b425b;
    border: 1px solid #2b425b;
    width: 95%;
    margin: 10px 0;
    padding: 2px
  }

  .documentBody.questionnaire #questions .question .extraData .extraDataWrapper input[type=text]:focus {
    border: 1px solid #00f
  }

  .documentBody.questionnaire #questions .question .extraData .extraDataWrapper input[type=date] {
    background: #fff;
    color: #2b425b;
    border: 1px solid #2b425b;
    margin: 10px 0;
    padding: 2px
  }

  .documentBody.questionnaire #questions .question .extraData .extraDataWrapper input[type=date]:focus {
    border: 1px solid #00f
  }

  .documentBody.questionnaire #questions .question .extraData .extraDataWrapper input[type=datetime-local] {
    background: #fff;
    color: #2b425b;
    border: 1px solid #2b425b;
    margin: 10px 0;
    padding: 2px
  }

  .documentBody.questionnaire #questions .question .extraData .extraDataWrapper input[type=datetime-local]:focus {
    border: 1px solid #00f
  }

  .documentBody.questionnaire #results {
    margin-bottom: 10px
  }

  .documentBody.questionnaire #results .result.notDisplayed {
    display: none !important
  }

  .documentBody.questionnaire #documentControls INPUT {
    padding: 10px 20px;
    background-color: #0094cd;
    color: #fff
  }

  .documentBody.questionnaire #documentControls INPUT:hover {
    cursor: pointer;
    background-color: #006;
    color: #fff !important;
    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    transition: all .4s ease-out
  }

  .documentBody.questionnaire #documentControls INPUT.btn_inactive {
    display: none
  }

  .documentBody.questionnaire #documentControls INPUT#btn_next {
    margin-right: 10px
  }

  .documentBody.questionnaire #documentControls INPUT#btn_finish {
    margin-right: 10px
  }

  .documentBody.questionnaire #documentControls INPUT#btn_reset {
    float: right;
    display: initial
  }

  .documentBody.conditional #questions {
    padding-top: 10px;
    margin-bottom: 10px
  }

  .documentBody.conditional #questions .question {
    margin-bottom: 10px;
    border-bottom: 1px solid #808080
  }

  .documentBody.conditional #questions .question .question_title {
    margin: 10px 0
  }

  .documentBody.conditional #questions .question.notDisplayed {
    display: none !important
  }

  .documentBody.conditional #questions .question.mandatory .question_title:after {
    content: "*";
    color: #f00
  }

  .documentBody.conditional #questions .question .optionsHolder {
    margin: 0
  }

  .documentBody.conditional #questions .question .optionsHolder .selectedOption {
    display: inline-block;
    padding: 0 10px;
    border: 1px solid;
    line-height: 0;
    margin-right: 5px
  }

  .documentBody.conditional #questions .question .optionsHolder .selectedOption p {
    margin-top: 10px
  }

  .documentBody.conditional #questions .question .optionsHolder .selectedOption .optionText {
    display: inline-block
  }

  .documentBody.conditional #questions .question .optionsHolder .selectedOption .removeSelectedOption {
    margin-left: 15px;
    cursor: pointer
  }

  .documentBody.conditional #questions .question .answers {
    margin-bottom: 10px
  }

  .documentBody.conditional #questions .question .answers .answer .answer_title .answerwrapper.box input[type="radio"],.documentBody.conditional #questions .question .answers .answer .answer_title .answerwrapper.box input[type="checkbox"] {
    display: none !important
  }

  .documentBody.conditional #questions .question .answers .answer .answer_title .answerwrapper.box input[type="radio"]+label,.documentBody.conditional #questions .question .answers .answer .answer_title .answerwrapper.box input[type="checkbox"]+label {
    border-style: solid;
    border-width: 1px;
    background-color: #fff;
    padding: 5px 20px;
    margin-top: 0;
    color: #000;
    width: 50%;
    cursor: pointer;
    display: inline-block
  }

  .documentBody.conditional #questions .question .answers .answer .answer_title .answerwrapper.box input[type="radio"]:checked+label,.documentBody.conditional #questions .question .answers .answer .answer_title .answerwrapper.box input[type="checkbox"]:checked+label {
    background-color: #3c0
  }

  .documentBody.conditional #results {
    margin-bottom: 10px
  }

  .documentBody.conditional #results .result.notDisplayed {
    display: none !important
  }

  .documentBody.errorpattern.kc_dialog_started .documentSummary {
    display: none
  }

  .documentBody.errorpattern .kc_invisible {
    display: none !important
  }

  .documentBody.errorpattern #ep_questionContainer #ep_questionContainerTitle {
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    margin-right: 30px
  }

  .documentBody.errorpattern #ep_questionContainer #ep_questionSummary {
    font-weight: 600;
    font-style: italic
  }

  .documentBody.errorpattern #ep_questionContainer #ep_questionContainerAnswers {
    margin-bottom: 20px
  }

  .documentBody.errorpattern #ep_questionContainer #ep_questionContainerAnswers SPAN,.documentBody.errorpattern #ep_questionContainer #ep_questionContainerAnswers .nolink {
    padding: 10px 20px;
    background-color: #0094cd;
    color: #fff;
    display: block;
    margin-bottom: 5px
  }

  .documentBody.errorpattern #ep_questionContainer #ep_questionContainerAnswers SPAN:hover,.documentBody.errorpattern #ep_questionContainer #ep_questionContainerAnswers .nolink:hover {
    cursor: pointer;
    background-color: #006;
    color: #fff !important;
    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    transition: all .4s ease-out
  }

  .documentBody.errorpattern #ep_questionContainer #ep_questionContainerAnswers SPAN.mark_answer_selected,.documentBody.errorpattern #ep_questionContainer #ep_questionContainerAnswers .nolink.mark_answer_selected {
    background-color: #3c0
  }

  .documentBody.errorpattern #ep_documentControls {
    width: 100%;
    display: inline-block
  }

  .documentBody.errorpattern #ep_documentControls INPUT {
    padding: 10px 20px;
    background-color: #0094cd;
    color: #fff
  }

  .documentBody.errorpattern #ep_documentControls INPUT:hover {
    cursor: pointer;
    background-color: #006;
    color: #fff !important;
    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    transition: all .4s ease-out
  }

  .documentBody.errorpattern #ep_documentControls INPUT.btn_inactive {
    display: none
  }

  .documentBody.errorpattern #ep_documentControls INPUT#btn_next {
    float: right
  }

  .documentBody.errorpattern #ep_solutionsContainer H3 {
    font-size: 20px;
    font-weight: 600
  }

  .documentBody.errorpattern #ep_solutionsContainer #ep_possibleAnswers {
    padding-left: 15px
  }

  .documentBody.errorpattern #ep_solutionsContainer #ep_possibleAnswers .ep_solution_element {
    margin-bottom: 10px
  }

  .documentBody.errorpattern #ep_solutionsContainer #ep_possibleAnswers .ep_solution_element DIV,.documentBody.errorpattern #ep_solutionsContainer #ep_possibleAnswers .ep_solution_element A {
    font-size: 16px
  }

  .documentBody.errorpattern #ep_solutionsContainer #ep_possibleAnswers .ep_solution_element .ep_solution_summary {
    padding-left: 10px
  }

  .documentBody.errorpattern #ep_historyContainer {
    margin-top: 20px
  }

  .documentBody.errorpattern #ep_historyContainer #ep_historyContainer_header {
    border-bottom: 1px solid
  }

  .documentBody.errorpattern #ep_historyContainer #ep_historyContainer_header H3 {
    font-size: 20px;
    font-weight: 600;
    display: inline-block
  }

  .documentBody.errorpattern #ep_historyContainer #ep_historyContainer_header SPAN {
    float: right;
    margin-top: 10px
  }

  .documentBody.errorpattern #ep_historyContainer #ep_historyContainer_content #historyTreeDiv H3 {
    font-size: 18px;
    font-weight: 400
  }

  .documentBody.errorpattern #ep_historyContainer #ep_historyContainer_content #historyTreeDiv H3 SPAN {
    float: right
  }

  .documentBody.errorpattern #ep_historyContainer #ep_historyContainer_content #historyTreeDiv SPAN {
    font-size: 18px;
    font-weight: 400;
    margin-left: 20px
  }

  .documentBody.errorpattern #ep_restartModalContainer {
    border: 1px solid;
    box-shadow: 0 0 5px 3px #a9a9a9;
    padding: 20px;
    display: block;
    transform: translateY(-50%);
    background-color: #fff;
    width: 100%
  }

  .documentBody.errorpattern #ep_restartModalContainer #ep_restartModalContainer_content #ep_restartModalContainer_close {
    position: absolute;
    top: 5px;
    right: 5px;
    transform: translateX(-50%);
    color: #f00;
    font-size: 30px !important
  }

  .documentBody.errorpattern #ep_restartModalContainer #ep_restartModalContainer_content #ep_restartModalContainer_close:hover {
    cursor: pointer
  }

  .documentBody.errorpattern #ep_restartModalContainer #ep_restartModalContainer_content P {
    margin-bottom: 25px
  }

  .documentBody.errorpattern #ep_restartModalContainer #ep_restartModalContainer_content BUTTON {
    padding: 10px 20px;
    background-color: #0094cd;
    color: #fff
  }
}
@primary-color: #1e4f9f;