.kbot-container {
  padding: 0px;
  .kbot-card {
    height: 100%;
    width: 100%;
    margin: 0px auto;
    border: none;
    position: relative;
    display: flex;
    flex-direction: column;

    .ant-avatar {
      margin-bottom: 16px;
      margin-right: 8px;
      flex: none;
    }
    .ant-card-head-title {
      padding: 16px 0px;
    }
    .ant-card-head {
      flex: none;
      /* Below selector used for title bar of the bot style. */
      padding: 0px 16px;
      background: #1e4f9f;
      color: #fff;
      font-weight: bold;
      .bot-title {
        text-align: center;
        .ant-typography {
          color: #fff;
        }
      }
      .bot-extra {
        text-align: right;
      }
      .ant-row {
        .ant-btn {
          padding: 0px;
          color: #fff;
        }
        .anticon {
          font-size: 22px;
        }
      }
    }
    .ant-card-body {
      display: flex;
      flex: 1;
      height: calc(100% - 65px);
      flex-direction: column;
      & > .messages-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .messages-container {
        /* control style for the whole messages container */
        flex: 1;
        padding: 16px;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-bottom: 0px;
      }
      .pdf-container {
        height: 100%;
        overflow-y: hidden;
        overflow-x: hidden;
        iframe {
          width: 100%;
          height: 100%;
        }
      }
      .message-holder {
        margin-bottom: 16px;
        margin-right: 0px;
        flex-grow: 1;
        overflow: hidden;
        overflow-wrap: break-word;
        .debug-btn {
          /* for debug button style */
          cursor: pointer;
          border-radius: 0px 2px 2px 2px;
        }
        .ant-tag {
          margin-right: -2px;
        }

        .ant-avatar {
          /* for bot avatar */
          // margin-top: 22px;
          padding: 1px;
          background-color: #fff;
          img {
            cursor: none;
          }
        }

        img {
          /* for any image that appears in bot message */
          width: 100%;
          cursor: pointer;
        }

        img.loading-img {
          /* Below selector used loading image style */
          width: 30px !important;
        }

        .ant-btn {
          /* Below selector used for the buttons which appears in bot message. */
          height: auto !important;
          white-space: inherit !important;
        }
        .ant-btn-link {
          padding: 0px !important;
        }
      }

      .message {
        /* Below selector used for bot message style. */
        background: #ececec;
        border-radius: 15px 15px 15px 0px;
        padding: 16px;
        margin-bottom: -2px;
        color: rgb(51, 51, 51) !important;
        font-size: 15px;
        .audio-icon {
          margin-right: 8px;
        }
      }

      .message.agent {
        /* Below selector used for agent message style. */
        background: #1e4f9f;
        color: #fff !important;
      }
      .message.startChat, .message.closeChat {
        background: #fff;
        border-radius: 0px;
        padding: 0;
        margin: 0px;
        color: #1e4f9f;
        border-top: 1px dashed #1e4f9f;
        margin-top: 16px;
        height: 1px;
        margin-left: -32px;
        margin-right: -64px;
      }

      .message.user {
        /* Below selector used for user message style. */
        background: #5f5f5f;
        color: #fff !important;
        border-radius: 15px 15px 0px 15px;
        margin-left: 20%;
        margin-right: 0px;
      }

      .options-container {
        /* control style for the whole options container */
        flex: none;
        width: 100%;
        border-top: 1px solid #eee;
        background: #fff;
        z-index: 999;
        padding: 16px;
        left: 0px;
        .ant-spin-nested-loading {
          .ant-btn {
            margin-bottom: 8px;
          }
          .ant-btn:last-child {
            margin-bottom: 0px;
          }
        }
        .ant-pagination-prev .ant-btn {
          margin-bottom: 8px;
        }
        .ant-pagination-next .ant-btn {
          margin-top: 8px;
        }
        .option-holder {
          padding-bottom: 10px;
        }
        .ant-tabs-content-holder {
          display: none;
        }
        .ant-tabs {
          display: block !important;
        }
        .ant-tabs-tab {
          padding: 0px;
          display: block;
          margin: 5px 0px 0px;
        }
        .ant-tabs-nav {
          max-height: 150px;
        }

        .searchString {
          font-weight: bold;
        }
      }

      .input-container {
        /* control style for input area at the bottom of the bot */
        flex: none;
        width: 100%;
        border-top: 1px solid #eee;
        background: #fff;
        z-index: 999;
        left: 0px;
        .input-holder {
          padding: 8px;
          .upload-file-btn {
            width: 100%;
        }
        }
        .ant-form-item {
          margin-bottom: 0px;
        }
      }
    }
  }

  .ant-list-pagination {
    /* control pagination style for long options lists */
    margin-top: 0px;
  }
  div.ant-list :first-of-type {
    .ant-pagination-simple {
      li {
        display: none;
      }
      li.ant-pagination-prev {
        margin-right: 0px;
        line-height: normal;
        display: block;
        height: auto !important;
        button {
          /*  Below selector used for options navigation for previous result set. */
          line-height: 1.5715;
          position: relative;
          display: inline-block;
          font-weight: 400;
          white-space: nowrap;
          text-align: center;
          background-image: none;
          cursor: pointer;
          transition: all .3s cubic-bezier(.645,.045,.355,1);
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          touch-action: manipulation;
          height: 32px;
          padding: 4px 15px;
          font-size: 14px;
          border-radius: 2px;
          color: rgba(0,0,0,.85);
          background-color: #1e4f9f !important;
          border: 1px solid #1e4f9f;
          text-shadow: none;
          width: 100%;
          box-shadow: 0 2px 0 rgb(0 0 0 / 5%);

          .anticon {
            /* Below selector used for previous button icon style. */
            color: #fff;
          }
        }

      }
      li.ant-pagination-disabled {
        display: none;
      }
    }
  }

  div.ant-list :last-of-type {
    .ant-pagination-simple {
      li {
        display: none;
      }
      li.ant-pagination-next {
        display: block;
        height: auto !important;
        button {
          /* Below selector used for options navigation for next result set. */
          line-height: 1.5715;
          position: relative;
          display: inline-block;
          font-weight: 400;
          white-space: nowrap;
          text-align: center;
          background-image: none;
          cursor: pointer;
          transition: all .3s cubic-bezier(.645,.045,.355,1);
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          touch-action: manipulation;
          height: 32px;
          padding: 4px 15px;
          font-size: 14px;
          border-radius: 2px;
          color: rgba(0,0,0,.85);
          background-color: #1e4f9f !important;
          border: 1px solid #1e4f9f;
          text-shadow: none;
          width: 100%;
          box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
          margin-bottom: 0px !important;

          .anticon {
            /* Below selector used for next button icon style. */
            color: #fff;
          }
        }
      }
      li.ant-pagination-disabled {
        display: none;
      }
    }
  }


  .anticon-bell {
    color: #fff;
  }
  .unsubscribed-btn .anticon-bell {
    color: #06873e;
  }
  .kbot-card.debug-mode {
    .ant-card-head {
      background-color: #990000 !important;
    }
  }
  .hidden, .notDisplayed {
    display: none !important;
  }
  #questions .notDisplayed {
    display: block;
  }
  .map-holder {
    height: 250px;
    width: 100%;
  }
  .error-holder {
    background: red;
    color: white;
    padding: 5px;
    height: auto !important;
  }
}

.bot-lightbox-modal {
  /* control style for bot feedback modal*/
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  max-width: auto !important;
  padding: 0px !important;
  margin: 0px !important;

  img {
    /* control style for any image that appear in modal */
    max-width: 90%;
    max-height: 90%;
    display: block;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .ant-modal-content {
    position: initial;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-modal-body {
    padding: 0px;
  }
}

@media only screen and (max-width: 760px) {
  .chatbot-notin-iframe .ant-tooltip {
    display: none;
  }
}

@media (min-width: 600px) {
  .kbot-container {
    padding: 5% 0px;

    .kbot-card {
      width: 450px;
      margin: 0px auto;
      box-shadow: 0px 0px 15px #d7d5d5;
      border: 1px solid #e1e1e1;
    }
  }
}

@primary-color: #1e4f9f;