.chatbot-upload-modal {
  .ant-modal-body {
    padding-bottom: 0px;
  }
  .ant-modal-content {
    border: 1px solid #004f9f;
    border-radius: 6px;
  }
  .ant-modal-footer button:first-child {
    border-color: #d9e5f1;
    background-color: #d9e5f1;
    color: #004f9f;
  }
  .upload-error {
    padding: 0px;
    margin-bottom: 2px;
    border: none;
  }
  .upload-area {
    font-family: inherit;
    line-height: 22.4px;
    letter-spacing: .2px;
    border: solid 1px #6695c5 !important;
    border-radius: 3px;
    background-color: #f2f6fa;
  }
  .ant-modal-title {
    font-size: 18px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: .2px;
    color: #004f9f !important;
  }
}
@primary-color: #1e4f9f;